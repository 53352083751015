<template>
  <div class="expandable-text">
    <!-- Trigger buttons -->
    <div class="trigger-text">
      <span @click="toggleText(0)">Experience</span>
      <span @click="toggleText(1)">Education</span>
      <span @click="toggleText(2)">Languages</span>
    </div>


    <div class="expand-section" :ref="'expandSection0'">
      <slot name="1"></slot>
    </div>

    <div class="expand-section" :ref="'expandSection1'">
      <slot name="2"></slot>
    </div>

    <div class="expand-section" :ref="'expandSection2'">
      <slot name="3"></slot>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeButton: null, // Track which section is active
    };
  },
  methods: {
    toggleText(buttonNumber) {
      // If the same button is clicked, collapse it
      if (this.activeButton === buttonNumber) {
        this.collapseSection(buttonNumber);
        this.activeButton = null;
      } else {
        // If switching to another section, collapse the current one
        if (this.activeButton !== null) {
          this.collapseSection(this.activeButton);
        }
        this.activeButton = buttonNumber; // Set the new active section
        this.$nextTick(() => {
          this.expandSection(buttonNumber);
        });
      }
    },

    expandSection(buttonNumber) {
      const section = this.$refs[`expandSection${buttonNumber}`];
      if (section) {
        section.style.height = section.scrollHeight + 'px'; // Set the height to the content's scrollHeight

        section.addEventListener(
            'transitionend',
            () => {
              section.style.height = 'auto'; // After expanding, reset height to auto for natural content flow
            },
            {once: true}
        );
      }
    },

    collapseSection(buttonNumber) {
      const section = this.$refs[`expandSection${buttonNumber}`];
      if (section) {
        // Set height to its current scroll height before collapsing
        section.style.height = section.scrollHeight + 'px';
        section.offsetHeight; // Trigger reflow
        this.$nextTick(() => {
          section.style.height = '0px'; // Then collapse the section to 0 height
        });
      }
    }
  }
};

</script>

<style scoped>
.trigger-text {
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.expandable-text {
  position: relative;
  display: block;
}

.expand-section {
  overflow: hidden;
  height: 0;
  transition: height 0.3s ease-out;
}
</style>