<template>
  <div class="main-page">
    <div class="card">
      <div class="text-box">
        <div class="about">
          <h1>My name is <strong class="name">Jakub Borcel</strong> <br>I also go by <strong
              class="name">Jay.</strong></h1>
          <p>I'm a Bachelor's in Computing Science student and full-stack Software Developer. I'm currently living in
            Italy.</p>
        </div>
        <hr>

        <ExpandText
            expandable_text="hello">
          <template #1>
            <ExperienceComponent />
          </template>
          <template #2>
          <EducationComponent />
          </template>
          <template #3>
          <LanguagesComponent />
          </template>
        </ExpandText>

        <hr>
        <div class="other">
          <a href="https://old.jborcel.com/notes/notes.html">University Notes <i class="fas fa-external-link-alt"></i></a>
        </div>

        <div class="social-links">
          <a href="https://github.com/jaytroy">
            <i class="fab fa-github social-link" ref=""></i>
          </a>
          <a href="https://www.linkedin.com/in/jakub-borcel/">
            <i class="fab fa-linkedin social-link" ref=""></i>
          </a>
        </div>
      </div>
      <a href="https://old.jborcel.com/" id="old" class="text-link">Old site</a>
    </div>
  </div>
</template>

<script>
import ExpandText from "@/components/ExpandComponent.vue";
import ExperienceComponent from "@/components/expandable/ExperienceComponent.vue";
import EducationComponent from "@/components/expandable/EducationComponent.vue";
import LanguagesComponent from "@/components/expandable/LanguagesComponent.vue";

export default {
  name: 'MainPage',
  components: {
    LanguagesComponent,
    EducationComponent,
    ExperienceComponent,
    ExpandText
  }
}
</script>

<style scoped>
.main-page {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../assets/bg.png');
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100%;
  position: relative;
  overflow: hidden;
  font-family: Lato, serif;
}

.main-page::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(6px);
  z-index: 1;
}

.card {
  background-color: rgba(255, 255, 255, 1);
  width: 90%;
  height: auto;
  max-width: 500px;
  max-height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  //color: white;
  //border-radius: 10px;
  position: relative;
  z-index: 2;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transform: translateY(20px);
  animation: fadeInUp 1s forwards;
}

@keyframes fadeInUp {
  from {
    opacity: 1;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.text-box {
  background-color: rgba(255, 255, 255, 1);
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  margin: 10px;
  padding: 25px;
  box-sizing: border-box;
  position: relative;
}

.text-box * {
  margin: 3px 0;
  padding: 0;
}

a {
  color: black;
  text-decoration: none;
}

#old {
  position: absolute;
  bottom: -20px;
  right: 0px;
  color: white;
  text-decoration: underline dotted;
}

.text-link {
  text-decoration: underline dotted;
}

.text-link:hover {
  color: aqua !important;
}

.social-links {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-size: 30px;
  z-index: 3 /* Ensure it has a higher z-index */
}

.social-link {
  margin: 0 5px;
}

.name {
  color: rgba(128, 163, 236, 1);
}

p {
  color: gray;
}

hr {
  display: block;
  width: 60%;
  margin: 20px auto !important;
  border: none;
  border-top: 1px solid lightgray;
}

.experience {
  display: flex;
  justify-content: space-around;
  margin: 20px 0;
}

.other {
  display: flex;
  justify-content: center;
  padding-bottom: 40px;
}

.other a {
  text-decoration: none;
  transition: transform 0.1s ease, box-shadow 0.1s ease;
}

.other a:hover {
  transform: translateY(2px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
</style>