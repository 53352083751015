<script setup>

</script>

<template>
  <div class="inner-container">
    <table>
      <tr>
        <td>English</td>
        <td>(Native)</td>
      </tr>
      <tr>
        <td>Czech</td>
        <td>(Native)</td>
      </tr>
      <tr>
        <td>Slovak</td>
        <td>(Native)</td>
      </tr>
      <tr>
        <td>French</td>
        <td>(A1)</td>
      </tr>
      <tr>
        <td>Italian</td>
        <td>(A1)</td>
      </tr>
      <tr>
        <td>Russian</td>
        <td>(Basic)</td>
      </tr>
    </table>
  </div>
</template>

<style scoped>
.inner-container {
  align-content: center;
  align-items: center;
  justify-content: center;
  display: flex;
}

td {
  margin: 50px;
}

td:first-child {
  text-align: right;
}

td:last-child {
  text-align: left;
  font-style: italic;
  color: gray;
}
</style>