<template>
  <MainPage />
</template>

<script>
import MainPage from "@/components/MainPage.vue";

export default {
  name: 'App',
  components: {
    MainPage
  }
}
</script>

<style>
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  //overflow: hidden;
}
#app {
  height: 100%;
  width: 100%;
}
</style>
