<script setup>

</script>

<template>
  <div class="innerContainer">
    I've worked in software development and market research.
  </div>
</template>

<style scoped>

</style>