<template>
  <div class="inner-container">
    <div class="info-strip">
      <img src="../../assets/rug.png" alt="RUG logo">
      <p><a href="https://www.rug.nl/">University of <br>Groningen</a></p>
      <p>BSc in Computing <br>Science</p>
      <p>2022-2025</p>
    </div>
    <div class="info-strip">
      <img src="../../assets/sapi.png" alt="Sapi logo">
      <p><a href="https://www.uniroma1.it/">University of <br>Sapienza</a></p>
      <p>Erasmus exchange <br></p>
      <p>2024-2025 <br>Fall semester</p>
    </div>
  </div>
</template>

<style scoped>
.inner-container {
  max-height: 100vh;
  display: block;
}

.info-strip {
  align-items: center;
  display: flex;
  justify-content: space-around;
}

img {
  display: inline-block;
  width: 10%;
}

a {
  color: black;
  text-decoration: none;
}

a:hover {
  color: grey;
  text-decoration: dotted !important;
}
</style>